<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })

const {
  query,
  history,
  results,
  selected,
  openBlank,
  state,

  handleClose,
  handleClick,
  handleArrowUp,
  handleArrowDown,
} = useGlobalSearch()

const separator = computed(() => {
  const hasArticle = results.value.filter(result => result.type === 'article')
  const hasApp = results.value.find(result => result.type === 'app')

  return {
    show: hasApp && hasArticle.length,
    onIndex: hasArticle.length - 1,
  }
})

onKeyStroke('ArrowUp', (e) => {
  e.preventDefault()
  handleArrowUp()

  const optionLink: HTMLAnchorElement | null = document.querySelector(`#global-search-option-${selected.value} a`)

  if (optionLink)
    optionLink.focus()
})
onKeyStroke('ArrowDown', (e) => {
  e.preventDefault()
  handleArrowDown()

  const optionLink: HTMLAnchorElement | null = document.querySelector(`#global-search-option-${selected.value} a`)

  if (optionLink)
    optionLink.focus()
})
onKeyStroke('Enter', (e) => {
  e.preventDefault()

  const optionLink: HTMLAnchorElement | null = document.querySelector(`#global-search-option-${selected.value} a`)

  if (optionLink)
    optionLink.click()
})

const options = computed(() => {
  if (state.value === 'history')
    return history.value

  return results.value
})
</script>

<template>
  <BlockSearchModal @close="handleClose">
    <BlockSearchHeader
      v-model="query"
      :selected="selected"
      @reset="query = ''"
      @close="handleClose"
    />

    <div class="flex-grow max-h-full py-4 overflow-y-auto bg-white md:flex-grow-0">
      <p v-if="state === 'history'" class="px-5 pb-3 text-sm font-semibold text-gray-500 font-display">
        {{ t('latest-search') }}
      </p>
      <p v-else-if="state === 'results'" class="px-5 pb-3 text-sm font-semibold text-gray-500 font-display">
        {{ t('most-relevant') }}
      </p>
      <ul
        id="global-search-options"
        role="listbox"
        aria-labelledby="global-search-label"
        :class="{ separator: separator.show && state === 'results' }"
      >
        <BlockSearchOption
          v-for="(result, index) in options"
          :id="`global-search-option-${index}`"
          :key="result.objectID"
          :result="result"
          :active="index === selected"
          :blank="openBlank"
          :class="{
            'last-article': index === separator.onIndex && state === 'results',
          }"
          @click="handleClick"
          @hover="selected = index"
        />
      </ul>
      <p v-if="state === 'empty'" class="px-4 pt-10 pb-12 font-medium text-center text-gray-500">
        {{ t('no-results') }} <strong>{{ query }}</strong>
      </p>
      <p v-if="state === 'history-empty'" class="px-4 pt-10 pb-12 font-medium text-center text-gray-500">
        {{ t('start-typing') }}
      </p>
    </div>
  </BlockSearchModal>
</template>

<style scoped lang="scss">
.separator {
  .last-article:after {
    content: '';
    display: block;
    height: 1rem;
    width: calc(100% - 2rem);
    // margin: 0.5rem 1rem;
    // background-color: theme('colors.gray.200');
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "most-relevant": "Most relevant results",
    "latest-search": "Latest search",
    "no-results": "No results found for",
    "start-typing": "Start typing to search our applications and articles"
  },
  "fr": {
    "most-relevant": "Résultats les plus pertinents",
    "latest-search": "Dernières recherches",
    "no-results": "Aucun résultat pour",
    "start-typing": "Écrivez pour chercher parmis nos applications et articles"
  }
}
</i18n>
