<script setup lang="ts">
import type { SearchResult } from '~/composables/search'

const props = defineProps<{
  result: SearchResult
  active: boolean
  blank?: boolean
}>()

const emit = defineEmits<{
  (event: 'hover'): void
  (click: 'click', result: SearchResult): void
}>()

const localePath = useLocalePath()
const { t, locale } = useI18n({ useScope: 'local' })

const to = computed(() => {
  const lang = locale.value as 'en' | 'fr'
  const fallbackLang = lang === 'en' ? 'fr' : 'en'

  if (props.result.type === 'app')
    return localePath({ name: 'apps-id', params: { id: props.result.data.id } })
  else if (props.result.type === 'article')
    return localePath({ name: 'resources-articles-slug', params: { slug: `${props.result.data.slug[lang] || props.result.data.slug[fallbackLang]}` } })
  else
    return ''
})

const data = computed(() => {
  const lang = locale.value as 'en' | 'fr'
  const fallbackLang = lang === 'en' ? 'fr' : 'en'

  switch (props.result.type) {
    case 'app':
      return {
        title: props.result.data.title[lang] || props.result.data.title[fallbackLang] || '',
        description: props.result.data.developerName,
        img: {
          src: props.result.data.icon,
          alt: props.result.data.title[lang] || props.result.data.title[fallbackLang] || '',
        },
      }
    case 'article':
      return {
        title: props.result.data.title[lang] || props.result.data.title[fallbackLang] || '',
        description: props.result.data.description[lang] || props.result.data.description[fallbackLang] || '',
        timeToRead: props.result.data.timeToRead[lang] || props.result.data.timeToRead[fallbackLang] || 0,
        img: {
          src: props.result.data.image,
          alt: props.result.data.imageAlt[lang] || props.result.data.imageAlt[fallbackLang] || '',
        },
      }
    default:
      return {
        title: '',
        description: '',
        img: {
          src: '',
          alt: '',
        },
      }
  }
})

const trackingAttrs = computed(() => {
  switch (props.result.type) {
    case 'app':
      return {
        'data-x-name': 'global_search_result_app',
        'data-xp-uid': props.result.data.id,
      }
    case 'article':
      return {
        'data-x-name': 'global_search_result_article',
        'data-xp-slug': props.result.data.slug,
      }
    default:
      return { 'data-x-name': 'global_search_result' }
  }
})
</script>

<template>
  <li
    role="option"
    tabindex="-1"
    :aria-selected="`${active}`"
    class="flex flex-col"
  >
    <NuxtLink
      v-bind="trackingAttrs"
      :to="to"
      class="relative w-full py-3 pl-3.5 pr-5 border-l-[6px] cursor-pointer"
      :class="{
        'bg-gray-100 border-blue-500': active,
        'border-white': !active,
      }"
      :target="blank ? '_blank' : '_self'"
      @click="() => emit('click', result)"
      @mouseenter="() => emit('hover')"
    >
      <div class="flex items-center">
        <img
          :src="data.img.src"
          :alt="data.img.alt"
          width="36"
          height="36"
          class="flex-none mr-5 rounded-md shadow-fields"
        >
        <div class="flex-grow flex-shrink">
          <div class="text-sm font-bold leading-4 font-display line-clamp-1">
            {{ data.title }}
          </div>
          <div class="mt-0.5 items-center space-x-1 text-xs font-semibold text-gray-500 line-clamp-1">
            <template v-if="data.timeToRead">
              <span>{{ t('time-to-read', data.timeToRead) }}</span>
              <span> · </span>
            </template>
            <span>{{ data.description }}</span>
          </div>
        </div>
        <div class="flex items-center self-stretch justify-end flex-none pl-10 text-sm font-medium text-gray-500">
          {{ t(`type.${result.type}`) }}
        </div>
      </div>
    </NuxtLink>
  </li>
</template>

<i18n lang="json">
{
  "en": {
    "type": {
      "app": "app",
      "article": "article"
    },
    "time-to-read": "{n} min read"
  },
  "fr": {
    "type": {
      "app": "appli",
      "article": "article"
    },
    "time-to-read": "{n} min de lecture"
  }
}
</i18n>
