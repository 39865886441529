<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

const emit = defineEmits<{
  (event: 'close'): void
}>()

const open = useSearchOpen()
</script>

<template>
  <TransitionRoot :show="open" as="template">
    <Dialog data-x-feature="global_search" class="relative z-modal" @close="emit('close')">
      <TransitionChild
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          x-click
          data-x-action="close"
          data-x-name="global_search_backdrop"
          aria-hidden="true"
          class="fixed inset-0 bg-violet-800/90"
        />
      </TransitionChild>

      <div class="fixed inset-0 flex items-start justify-center md:p-4">
        <DialogPanel class="relative z-10 flex flex-col w-full max-h-full max-md:min-h-full md:max-w-2xl">
          <slot />

          <BlockSearchFooter />
        </DialogPanel>

        <div
          x-click
          data-x-action="close"
          data-x-name="global_search_backdrop"
          aria-hidden="true"
          class="absolute inset-0"
        />
      </div>
    </Dialog>
  </TransitionRoot>
</template>
