<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <div class="justify-between hidden px-4 pt-3 pb-2 border-t border-gray-200 rounded-b-lg bg-gray-50 md:flex">
    <div class="flex space-x-4">
      <div class="flex gap-1 text-gray-500">
        <kbd class="flex items-center justify-center h-6 font-bold transition bg-white border border-gray-200 rounded-md shadow w-7 shadow-violet-900/5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
            <title>arrow up</title>
            <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
          </svg>
        </kbd>

        <kbd class="flex items-center justify-center h-6 font-bold transition bg-white border border-gray-200 rounded-md shadow w-7 shadow-violet-900/5">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
            <title>arrow down</title>
            <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
          </svg>
        </kbd>

        <div class="ml-1 text-sm font-medium">
          {{ t('navigate') }}
        </div>
      </div>

      <div class="flex text-gray-500">
        <kbd class="flex items-center justify-center h-6 font-bold transition bg-white border border-gray-200 rounded-md shadow w-7 shadow-violet-900/5">
          <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4">
            <title>enter</title>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.79313 17.768C7.93031 17.6241 8.00474 17.4316 8.00005 17.2328C7.99536 17.034 7.91194 16.8453 7.76813 16.708L3.62213 12.75H13.6251C15.0507 12.75 16.4178 12.1837 17.4258 11.1757C18.4338 10.1677 19.0001 8.80053 19.0001 7.37499C19.0001 5.94945 18.4338 4.5823 17.4258 3.57429C16.4178 2.56628 15.0507 1.99999 13.6251 1.99999H10.7501C10.5512 1.99999 10.3604 2.079 10.2198 2.21966C10.0791 2.36031 10.0001 2.55107 10.0001 2.74999C10.0001 2.9489 10.0791 3.13967 10.2198 3.28032C10.3604 3.42097 10.5512 3.49999 10.7501 3.49999H13.6251C14.6528 3.49999 15.6385 3.90824 16.3652 4.63495C17.0919 5.36165 17.5001 6.34727 17.5001 7.37499C17.5001 8.4027 17.0919 9.38832 16.3652 10.115C15.6385 10.8417 14.6528 11.25 13.6251 11.25H3.62213L7.76813 7.29299C7.91201 7.1556 7.99542 6.96669 8.00001 6.76781C8.00461 6.56893 7.93001 6.37637 7.79263 6.23249C7.65524 6.08861 7.46633 6.00519 7.26745 6.0006C7.06857 5.99601 6.87601 6.0706 6.73213 6.20799L1.23213 11.458C1.15876 11.528 1.10037 11.6122 1.06047 11.7054C1.02057 11.7987 1 11.8991 1 12.0005C1 12.1019 1.02057 12.2023 1.06047 12.2955C1.10037 12.3888 1.15876 12.473 1.23213 12.543L6.73213 17.793C6.87604 17.9302 7.06853 18.0046 7.2673 17.9999C7.46607 17.9952 7.65484 17.9118 7.79213 17.768H7.79313Z" fill="currentColor" />
          </svg>
        </kbd>
        <div class="ml-1.5 text-sm font-medium">
          {{ t('open') }}
        </div>
      </div>
    </div>

    <div class="flex gap-2 text-gray-500">
      <div class="text-sm font-medium">
        {{ t('close') }}
      </div>
      <kbd class="text-[8px] bg-white flex items-center justify-center font-bold transition w-7 h-6 border border-gray-200 rounded-md shadow shadow-violet-900/5">
        ESC
      </kbd>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "navigate": "Navigate",
    "open": "Open",
    "close": "Close"
  },
  "fr": {
    "navigate": "Naviguer",
    "open": "Ouvrir",
    "close": "Fermer"
  }
}
</i18n>
