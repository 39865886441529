<script setup lang="ts">
const props = defineProps<{
  modelValue: string
  selected: number
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'reset'): void
  (event: 'update:modelValue', value: string): void
}>()

const { t } = useI18n({ useScope: 'local' })
const query = useVModel(props, 'modelValue', emit)
</script>

<template>
  <form class="relative flex items-center pl-5 pr-4 bg-white border-b border-gray-200 md:rounded-t-lg" @submit.prevent>
    <label id="global-search-label" for="global-search-input" class="flex-none">
      <IconSectionsSearch class="w-6 h-6 text-gray-500" />
    </label>

    <input
      id="global-search-input"
      v-model="query"
      type="search"
      maxlength="64"

      aria-controls="global-search-options"
      aria-labelledby="global-search-label"
      aria-autocomplete="both"
      :aria-activedescendant="`global-search-option-${selected}`"

      autofocus="true"
      spellcheck="false"
      autocorrect="off"
      autocomplete="off"
      autocapitalize="off"
      enterkeyhint="go"

      :placeholder="t('search')"

      class="flex-grow flex-shrink pl-3 h-14 focus:outline-none"
    >

    <button
      v-show="query !== ''"
      type="reset"
      :title="t('clear-button')"
      :aria-label="t('clear-button')"
      class="flex items-center justify-center flex-none w-8 h-8 transition-colors rounded-md hover:bg-gray-100"
      @click="emit('reset')"
    >
      <IconAttributesBooleanCross class="w-4 h-4 text-gray-500" />
    </button>

    <button type="button" class="px-4 -mr-4 border-l md:hidden" @click="emit('close')">
      {{ t('close-button') }}
    </button>
  </form>
</template>

<i18n lang="json">
{
  "en": {
    "search": "Search",
    "clear-button": "Clear the query",
    "close-button": "Close"
  },
  "fr": {
    "search": "Rechercher",
    "clear-button": "Effacer la requête",
    "close-button": "Fermer"
  }
}
</i18n>
